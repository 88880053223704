import React, { useState } from "react";
import Select from "react-select";
import Label from "components/form/Label";
import { includes } from "lodash";
import CheckBox from "components/form/CheckBox";
import Profile from "components/Layout/Profile";
import Button from "components/form/Button";
import "./ReviewerDropdown.scss";

const ReviewerDropdown = ({
  label,
  isRequired,
  labelClass,
  options,
  placeholder,
  optionKey,
  optionValue,
  value,
  isLoading,
  disabled,
  name,
  onChange,
  id,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allocatedText, setAllocatedText] = useState(placeholder);
  const optId = optionKey || "id";
  const optVal = optionValue || "label";

  let fillValue = null;
  if (value) {
    const idArray = value?.split(",");
    fillValue = options?.filter((o) => includes(idArray, o[optId] + ""));
  }

  const handleCheckboxChange = (data) => {
    const isSelected = selectedOptions?.some((o) => o[optId] === data[optId]);
    const updatedOptions = isSelected
      ? selectedOptions?.filter((o) => o[optId] !== data[optId])
      : [...selectedOptions, data];
    setSelectedOptions(updatedOptions);
  };

  const handleAllocate = () => {
    const totalReviewers = selectedOptions?.length;
    setAllocatedText(
      totalReviewers
        ? `${totalReviewers} Reviewer${totalReviewers > 1 ? "s" : ""} Allocated`
        : placeholder
    );
    let allSelectedUsers = selectedOptions?.map((opt) => opt?.name)?.join(",");
    onChange({
      target: {
        id: id,
        value: allSelectedUsers,
        data: selectedOptions,
      },
    });
  };

  const CustomOption = (props) => {
    const { name, email, member_id, phone, allocated_review } =
      props?.data || {};
    const isChecked = selectedOptions?.some(
      (o) => o[optId] === props?.data[optId]
    );
    const handleClick = () => {
      handleCheckboxChange(props?.data);
      props?.selectOption(props?.data);
    };
    return (
      <div className="gap-3 custom-option" onClick={handleClick}>
        <div className="me-2 ">
          <CheckBox
            type="PRIMARY-ACTIVE"
            onClick={() => {}}
            isChecked={isChecked}
            readOnly
          />
        </div>
        <div className="user-info flex-grow-1 pointer">
          <div>
            <Profile
              url={
                "https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&w=600"
              }
              size="s-48"
              isRounded
            />
          </div>
          <div>
            <div className="text-14-400-18 color-black-olive cmb-12">
              {name}
            </div>
            <div className="text-12-400-15 color-black-olive">{member_id}</div>
          </div>
          <div>
            <div className="text-14-300-17 color-black-olive cmb-12">
              {email}
            </div>
            <div className="text-14-300-17 color-black-olive">{phone}</div>
          </div>
          <div>
            <div className="text-14-400-18 color-black-olive cmb-12">
              {"Allocated Review"}
            </div>
            <div className="text-14-400-18 color-black-olive">
              {allocated_review}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="reviewer-dropdown-container">
        <div className="w-100">
          {label && (
            <Label label={label} required={isRequired} className={labelClass} />
          )}
          <Select
            isMulti
            placeholder={allocatedText}
            options={options}
            name={name}
            getOptionLabel={(option) => option[optVal]}
            getOptionValue={(option) => option[optId]}
            isDisabled={disabled}
            isLoading={isLoading}
            value={fillValue}
            components={{ Option: CustomOption }}
            className="basic-multi-select"
            classNamePrefix="select"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            onChange={(selectedItems) => {
              let allSelectedUsers = selectedItems
                ?.map((opt) => opt?.name)
                ?.join(",");
              onChange({
                target: {
                  id: id,
                  value: allSelectedUsers,
                  data: selectedItems,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="d-flex mt-4 allocate-button">
        <Button
          text="Allocate"
          btnStyle="primary-dark"
          className="h-35 cps-20 cpe-20"
          onClick={() => {
            handleAllocate();
          }}
          disabled={true}
        />
      </div>
    </>
  );
};

export default ReviewerDropdown;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePreSignedUrl } from "utils/helpers";
import { eventAbstarctPath } from "utils/constants";
import FilePreview from "components/Layout/FilePreview";
import { useNavigate, useParams } from "react-router-dom";
import "./NewSubmittedAbstracts.scss";

const NewSubmittedAbstracts = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [paperList, setPaperList] = useState([]);
  const { eventData } = useSelector((state) => ({
    eventData: state.global.eventData || {},
  }));
  const { abstract_details = [] } = eventData;
  // let list = abstract_details.length > 0 ? abstract_details : [];
  const getAbstarctList = async (abstractData) => {
    const promises = abstractData?.map(async (elm) => {
      let response = "";
      let isImage = false;
      if (
        ["png", "jpg", "jpeg"].includes(elm?.abstract_path?.split(".").pop())
      ) {
        isImage = true;
        response = await generatePreSignedUrl(
          elm.abstract_path,
          eventAbstarctPath
        );
      }
      return { ...elm, s3File: response, isImage: isImage };
    });
    const results = await Promise.all(promises);
    setPaperList(results);
  };
  useEffect(() => {
    if (abstract_details?.length > 0) {
      getAbstarctList(abstract_details);
    }
  }, [abstract_details]);
  return (
    <div className="new-submitted-abstracts-container">
      {paperList?.map((elm, index) => {
        let {
          id,
          paper_title,
          event_id,
          abstract_id,
          abstract_path,
          isImage,
          s3File,
        } = elm;
        return (
          <React.Fragment key={index}>
            <div className="border rounded p-2 mb-3">
              <div className="d-flex align-items-center ">
                <div className="d-flex align-items-center gap-3">
                  <div>
                    {isImage ? (
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                      >
                        <img
                          src={s3File}
                          alt="abstarct"
                          className="fill fit-image"
                        />
                      </div>
                    ) : (
                      <FilePreview url={`http://${abstract_path}`} />
                    )}
                  </div>
                  <div className="">
                    <div className="mb-2 text-16-500">{abstract_id}</div>
                    <div
                      className="flex-grow-1 pointer paper-title"
                      onClick={() => {
                        navigate(
                          `/${params?.memberType}/my-profile/my-events/abstract/tracking/${event_id}/${id}`
                        );
                      }}
                    >
                      {paper_title}
                    </div>
                  </div>
                </div>
              </div>
              {/* {paperID === index && (
                <div className="new-submitted-abstracts-container cmt-30">
                  <NewViewPaper id={id} type="abstract" />
                </div>
              )} */}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default NewSubmittedAbstracts;

import React, { useEffect, useState } from "react";
import Card from "components/Layout/Card";
import { useNavigate, useParams } from "react-router-dom";
import { eventAbstarctPath, icons } from "utils/constants";
import BasicInfo from "./BasicInfo";
import Button from "components/form/Button";
import RadioInput from "components/form/RadioInput";
import TextArea from "components/form/TextArea";
import FileUpload from "components/form/FileUpload";
import { useDispatch } from "react-redux";
import {
  fetchJournalPapersDetails,
  fetchSubmittedAbstractsDetails,
  handleProceedToNext,
  updateEventsSubmittedAbstractStatus,
} from "store/slices";
import {
  downloadFile,
  formatDate,
  generatePreSignedUrl,
  getFilenameFromUrl,
  objectToFormData,
} from "utils/helpers";
import Loader from "components/Layout/Loader";
import DatePicker from "components/form/DatePicker";
import moment from "moment";
import ReviewerDropdown from "../ReviewerDropdown";
import UpdateAbstractByResourceForm from "../UpdateAbstractByResourceForm";
import { Formik } from "formik";
import * as Yup from "yup";
import "./NewPaperDetails.scss";

// const reviewList = [
//   {
//     name: "Phoenix Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "0",
//   },
//   {
//     name: "Mihir Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "2",
//   },
//   {
//     name: "Tahir Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "0",
//   },
//   {
//     name: "Yak Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "4",
//   },
//   {
//     name: "ABC Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "6",
//   },
//   {
//     name: "Xyz Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "0",
//   },
//   {
//     name: "Pqr Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "3",
//   },
//   {
//     name: "Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "0",
//   },
//   {
//     name: "Phoenix",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "8",
//   },
//   {
//     name: "Logi Baker",
//     member_id: "PROF-90695132",
//     email: "lorel123@gmail.com",
//     phone: "9876543210",
//     allocated_review: "0",
//   },
// ];

const NewPaperDetails = () => {
  const isEvent =
    window.location.pathname.includes("event-management") ||
    window.location.pathname.includes("my-events") ||
    window.location.pathname.includes("my-profile");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isSubmittedPaperDetail, setIsSubmittedPaperDetail] = useState(false);
  const [isRevisionDetails, setIsRevisionDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState("");
  const [isPaperDetail, setIsPaperDetail] = useState(true);
  const [paperData, setPaperData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [btnStatus, setBtnStatus] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedIndexes, setExpandedIndexes] = useState({});
  // const [formData, setFormData] = useState({
  //   plagiarism_comment: "",
  //   plagiarism_document: "",
  //   plagiarism_document_file_name: "",
  //   republish_date: "",
  //   is_republish_by_admin: "0",
  // });
  const [data, setData] = useState({
    paper_submitted_status: "",
    plagiarism_comment: "",
    plagiarism_document: "",
    review_comment: "",
    acceptance_status: "",
    registration_acceptance_letter: "",
    acceptance_date: "",
    paper_submitted_date: "",
    plagiarism_date: "",
    review_date: "",
    registration_date: "",
    plagiarism_document_file_name: "",
    acceptance_letter_file_name: "",
    republish_date: "",
    is_republish_by_admin: "0",
    is_remodify: "2",
    reviewing_file: "",
    reviewing_file_file_name: "",
  });
  const getPaperDetails = async () => {
    let response = null;
    if (isEvent) {
      response = await dispatch(fetchSubmittedAbstractsDetails(params.paperId));
    } else {
      response = await dispatch(fetchJournalPapersDetails(params.paperId));
    }
    const resData = response?.data || null;

    const statusData = resData?.submittedPapersStatus || {};
    setPaperData(resData);
    setData({
      ...data,
      ...statusData,
      plagiarism_document_file_name: getFilenameFromUrl(
        statusData?.plagiarism_document
      ),
      acceptance_letter_file_name: getFilenameFromUrl(
        statusData?.registration_acceptance_letter
      ),
    });
    setLoading(false);
    setBtnLoader("");
  };

  // const handelChange = (e) => {
  //   let newValue = cloneDeep(data);
  //   const id = e.target.id;
  //   const value = e.target.value;
  //   newValue = { ...newValue, [id]: value };
  //   if (id === "plagiarism_document") {
  //     const fileName = e.target.fileName;
  //     newValue = { ...newValue, plagiarism_document_file_name: fileName };
  //   }
  //   if (id === "registration_acceptance_letter") {
  //     const fileName = e.target.fileName;
  //     newValue = { ...newValue, acceptance_letter_file_name: fileName };
  //   }
  //   setData(newValue);
  // };
  // const handelChange = (e) => {
  //   const { id, value } = e.target;
  //   if (id === "plagiarism_document") {
  //     const fileName = e.target.fileName;
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       plagiarism_document: value,
  //       plagiarism_document_file_name: fileName,
  //     }));
  //   } else {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: value,
  //     }));
  //   }
  // };
  // const handleDateChange = (date) => {
  //   const { value } = date.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     republish_date: value,
  //   }));
  // };

  const initialValues = {
    plagiarism_comment: "",
    plagiarism_document: "",
    plagiarism_document_file_name: "",
    republish_date: "",
    is_republish_by_admin: "0",
  };

  const validationSchema = Yup.object().shape({
    plagiarism_document: Yup.string().required("Plagiarism report is required"),
    is_republish_by_admin: Yup.string().required("Selection is required"),
    // republish_date: Yup.string().required("Republish date is required."),
  });

  const handelSave = (values, btnStatus) => {
    setBtnLoader(btnStatus);
    let payloadData = {
      id: params.paperId,
    };

    switch (btnStatus) {
      case "1":
        payloadData = {
          ...payloadData,
          paper_submitted_status: "2",
          plagiarism_status: btnStatus,
          plagiarism_comment: values?.plagiarism_comment,
          plagiarism_document: values?.plagiarism_document,
        };
        break;
      case "2":
        payloadData = {
          ...payloadData,
          paper_submitted_status: "2",
          plagiarism_status: btnStatus,
          plagiarism_comment: values?.plagiarism_comment,
          plagiarism_document: values?.plagiarism_document,
          republish_date: values?.republish_date,
        };
        break;
      case "3":
        payloadData = {
          ...payloadData,
          paper_submitted_status: "2",
          plagiarism_status: btnStatus,
          plagiarism_comment: values?.plagiarism_comment,
          plagiarism_document: values?.plagiarism_document,
          republish_date: values?.republish_date,
        };
        break;

      default:
        break;
    }
    handelUpdate(payloadData);
  };

  const handelUpdate = async (obj) => {
    const payload = objectToFormData(obj);
    let response = await dispatch(updateEventsSubmittedAbstractStatus(payload));
    if (response?.status === 200) {
      getPaperDetails();
    } else {
      setBtnLoader("");
    }
  };

  const handleNext = async () => {
    setIsNextLoading(true);
    const response = await dispatch(
      handleProceedToNext(objectToFormData({ id: params.paperId }))
    );

    if (response?.status === 200) {
      getPaperDetails();
    } else {
      setIsNextLoading(false);
    }
    setIsNextLoading(false);
  };

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const toggleRevisionReadMore = (index) => {
    setExpandedIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    getPaperDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    abstract_path,
    paper_abstract,
    paperImage,
    revision_details,
    revision_reasons,
  } = paperData || {};
  const {
    paper_submitted_date: paperSubmittedDate,
    paper_submitted_status,
    plagiarism_comment: plagiarismComment,
    // plagiarism_document: plagiarismDocument,
    // plagiarism_document_file_name: plagiarismDocumentFileName,
    plagiarism_accepted_date: plagiarismAcceptedData,
    plagiarism_status: plagiarismStatus,
    process_to_next: processToNext,
    // is_republish_by_admin: isRepublish,
    // republish_date: republishDate,
    revision_status,
  } = data || {};
  const paperURL = isEvent ? abstract_path || "" : paperImage || "";
  const status = +paper_submitted_status || 1;
  const plagiarismAccepted = +plagiarismStatus === 1;
  const abstractText = paper_abstract
    ?.replace(/<\/?p>/g, "")
    ?.replace(/<\/?span[^>]*>/g, "")
    ?.trim();

  const abstractWords = abstractText?.split(" ")?.filter((word) => word);
  const maxWords = 70;

  // const loaderID = formData?.is_republish_by_admin === "1" ? "3" : "1";
  // const isReviewStateVisible = ["1", "3"].includes(plagiarismStatus);

  return (
    <div className="new-paper-details-container">
      {isEdit && (
        <UpdateAbstractByResourceForm
          onHide={() => {
            setIsEdit(false);
          }}
          elem={isEdit}
          handleSuccess={() => {
            getPaperDetails();
          }}
        />
      )}
      {!isLoading && (
        <Card className="cmb-18">
          <div className="bg-f4ff color-new-car text-14-500-17 cps-44 cpt-12 cpe-28 cpb-12 d-flex align-items-center gap-2">
            <div
              className="d-flex"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={icons.leftArrow}
                alt="back"
                className="h-21 me-3 pointer"
              />
            </div>
            <div
              className="d-flex align-items-center justify-content-between pointer flex-grow-1"
              onClick={() => {
                setIsPaperDetail(!isPaperDetail);
              }}
            >
              <div>{paperData?.paperId}</div>
              <i
                className={`${
                  isPaperDetail ? "bi bi-chevron-up" : "bi bi-chevron-down"
                }`}
                style={{ fontSize: "15px" }}
              />
            </div>
          </div>

          {isPaperDetail && <BasicInfo paperData={paperData} />}
        </Card>
      )}

      {isLoading ? (
        <Card className="cps-44 cpt-25 cpe-44 cpb-25">
          <div className="pt-5 pb-5">
            <Loader size="md" />
          </div>
        </Card>
      ) : (
        <Card className="cps-44 cpt-25 cpe-44 cpb-25 paper-tracking-section">
          {/* Abstract */}
          <div className="d-flex justify-content-start align-items-center">
            <div className="steps-block active">
              {/* 1 */}
              {status === 2 ? <img src={icons?.RightIcon} alt="right" /> : "1"}
            </div>
            <div>
              <div className="text-16-500-20 color-new-car d-flex align-items-center gap-2">
                Abstract
                {revision_details?.length === 0 && (
                  <img
                    src={icons?.primaryEditPen}
                    alt="pen"
                    className="pointer"
                    onClick={() => {
                      setIsEdit({
                        abstract_id: params.paperId,
                        paper_abstract: paper_abstract,
                        update_type: "Abstract",
                      });
                    }}
                  />
                )}
                <i
                  className="bi bi-chevron-down"
                  style={{ fontSize: "15px" }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div className="sparator-block">
              <span className="hr-line" />
            </div>

            <div className="w-100">
              {revision_details?.length > 0 ? (
                <>
                  <div className="submitted-paper-block border br-5 w-100 cmb-8">
                    <div
                      className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                      onClick={() => {
                        setIsSubmittedPaperDetail(!isSubmittedPaperDetail);
                      }}
                    >
                      <span>Submitted Paper</span>
                      <i
                        className={`${
                          isSubmittedPaperDetail
                            ? "bi bi-chevron-up"
                            : "bi bi-chevron-down"
                        }`}
                        style={{ fontSize: "15px" }}
                      />
                    </div>

                    {isSubmittedPaperDetail && (
                      <div className="cps-24 cpt-12 cpe-24 cpb-12">
                        {paperSubmittedDate && (
                          <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300-17">
                            <img src={icons.calendarCheck} alt="calendar" />
                            {formatDate(paperSubmittedDate)}
                          </div>
                        )}
                        {paper_abstract && (
                          <div className="cmb-16 text-14-300-24 color-black-olive">
                            <div>
                              {isExpanded ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: paper_abstract,
                                  }}
                                />
                              ) : (
                                <>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: paper_abstract
                                        ?.replace(/<\/?p>/g, "")
                                        ?.replace(/<\/?span[^>]*>/g, "")
                                        ?.split(" ")
                                        ?.slice(0, maxWords)
                                        ?.join(" "),
                                    }}
                                  />

                                  {abstractWords?.length > maxWords && (
                                    <>
                                      {"... "}
                                      <span
                                        onClick={toggleReadMore}
                                        className="text-14-500-23 color-new-car pointer"
                                      >
                                        Read More
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </div>

                            {isExpanded && (
                              <span
                                onClick={toggleReadMore}
                                className="text-14-500-23 color-new-car pointer"
                              >
                                Read Less
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {revision_details?.map((details, index) => {
                    const isOpen = isRevisionDetails === index;
                    const isExpanded = expandedIndexes[index];
                    return (
                      <div
                        className="revision-block border br-5 w-100 cmb-8"
                        key={index + 1}
                      >
                        <div
                          className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                          onClick={() => {
                            setIsRevisionDetails(isOpen ? null : index);
                          }}
                        >
                          <div className="d-flex align-items-center gap-3">
                            Revision {index + 1}{" "}
                            {revision_details?.length - 1 === index &&
                              details?.process_to_next === "1" && (
                                <img
                                  src={icons?.primaryEditPen}
                                  alt="pen"
                                  className="pointer"
                                  onClick={() => {
                                    setIsEdit({
                                      revision_id: details?.id,
                                      abstract_id: params.paperId,
                                      paper_abstract: paper_abstract,
                                      update_type: "Revision",
                                    });
                                  }}
                                />
                              )}
                          </div>
                          <i
                            className={`${
                              isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>

                        {isOpen && (
                          <div className="cps-24 cpt-12 cpe-24 cpb-12">
                            {paperSubmittedDate && (
                              <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300-17">
                                <img src={icons.calendarCheck} alt="calendar" />
                                {formatDate(paperSubmittedDate)}
                              </div>
                            )}
                            {paper_abstract && (
                              <div className="cmb-16 text-14-300-24 color-black-olive">
                                <div>
                                  {isExpanded ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: paper_abstract,
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: paper_abstract
                                            ?.replace(/<\/?p>/g, "")
                                            ?.replace(/<\/?span[^>]*>/g, "")
                                            ?.split(" ")
                                            ?.slice(0, maxWords)
                                            ?.join(" "),
                                        }}
                                      />
                                      {paper_abstract?.split(" ")?.length >
                                        maxWords && (
                                        <>
                                          {"... "}
                                          <span
                                            onClick={() =>
                                              toggleRevisionReadMore(index)
                                            }
                                            className="text-14-500-23 color-new-car pointer"
                                          >
                                            Read More
                                          </span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>

                                {isExpanded && (
                                  <span
                                    onClick={() =>
                                      toggleRevisionReadMore(index)
                                    }
                                    className="text-14-500-23 color-new-car pointer"
                                  >
                                    Read Less
                                  </span>
                                )}
                              </div>
                            )}
                            {paperURL && (
                              <div
                                className="text-16-500-20 color-7cff pointer cmb-16"
                                onClick={async () => {
                                  if (paperURL) {
                                    let downloadContent = "";
                                    if (isEvent) {
                                      downloadContent =
                                        await generatePreSignedUrl(
                                          paperURL,
                                          eventAbstarctPath
                                        );
                                    }

                                    dispatch(downloadFile(downloadContent));
                                  }
                                }}
                              >
                                Download Abstract File
                              </div>
                            )}
                            {revision_details?.length - 1 === index &&
                              details?.process_to_next === "1" && (
                                <div className="d-flex">
                                  <Button
                                    text="Proceed to Next"
                                    btnStyle="primary-dark"
                                    className="h-35 cps-20 cpe-20"
                                    onClick={handleNext}
                                    btnLoading={isNextLoading}
                                  />
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="cmt-14 cmb-19">
                  {paperSubmittedDate && (
                    <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300-17">
                      <img src={icons.calendarCheck} alt="calendar" />
                      {formatDate(paperSubmittedDate)}
                    </div>
                  )}
                  {paper_abstract && (
                    <div className="cmb-16 text-14-300-24 color-black-olive">
                      <div>
                        {isExpanded ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: paper_abstract,
                            }}
                          />
                        ) : (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: paper_abstract
                                  ?.replace(/<\/?p>/g, "")
                                  ?.replace(/<\/?span[^>]*>/g, "")
                                  ?.split(" ")
                                  ?.slice(0, maxWords)
                                  ?.join(" "),
                              }}
                            />

                            {abstractWords?.length > maxWords && (
                              <>
                                {"... "}
                                <span
                                  onClick={toggleReadMore}
                                  className="text-14-500-23 color-new-car pointer"
                                >
                                  Read More
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </div>

                      {isExpanded && (
                        <span
                          onClick={toggleReadMore}
                          className="text-14-500-23 color-new-car pointer"
                        >
                          Read Less
                        </span>
                      )}
                    </div>
                  )}
                  {/* {paper_abstract && (
                    <div
                      dangerouslySetInnerHTML={{ __html: paper_abstract }}
                      className="cmb-16 text-14-300-24 color-black-olive"
                    ></div>
                  )} */}
                  {paperURL && (
                    <div
                      className="cmb-16 text-16-500-20 color-7cff pointer"
                      onClick={async () => {
                        if (paperURL) {
                          let downloadContent = "";
                          if (isEvent) {
                            downloadContent = await generatePreSignedUrl(
                              paperURL,
                              eventAbstarctPath
                            );
                          }

                          dispatch(downloadFile(downloadContent));
                        }
                      }}
                    >
                      Download Abstract File
                    </div>
                  )}
                  {processToNext === "1" && (
                    <div className="d-flex">
                      <Button
                        text="Proceed to Next"
                        btnStyle="primary-dark"
                        className="h-35 cps-20 cpe-20"
                        onClick={handleNext}
                        btnLoading={isNextLoading}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Plagiarism */}
          <div className="d-flex justify-content-start align-items-center">
            <div
              className={`steps-block ${
                status >= 1 && processToNext === "0" ? "active" : "not-active"
              }`}
            >
              {plagiarismAccepted ? (
                <img src={icons?.RightIcon} alt="right" />
              ) : (
                "2"
              )}
            </div>
            <div>
              <div
                className={`text-16-500-20 d-flex align-items-center gap-2 ${
                  status >= 1 && processToNext === "0"
                    ? "color-new-car"
                    : "color-6866"
                }`}
              >
                Plagiarism{" "}
                <i
                  className="bi bi-chevron-down"
                  style={{ fontSize: "15px" }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div className="sparator-block">
              <span className="hr-line" />
            </div>

            <div className="cpt-14 cpb-14 w-50">
              {/* {!plagiarismStatus && */}
              {plagiarismStatus !== "1" &&
                revision_reasons?.length > 0 &&
                revision_reasons?.map((revComment, index) => {
                  return (
                    <div
                      className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16"
                      key={index}
                    >
                      {`Raised Revision ${index + 1}`}
                    </div>
                  );
                })}
              {/* ["1", "3"]?.includes(plagiarismStatus) &&
                  plagiarismComment && (
                    <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                      
                      {titleCaseString(plagiarismComment)}
                    </div>
                  )  */}

              {plagiarismStatus && plagiarismStatus !== "1" && (
                <>
                  {plagiarismAcceptedData && (
                    <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                      <img src={icons.calendarCheck} alt="calendar" />
                      {formatDate(plagiarismAcceptedData)}
                    </div>
                  )}
                </>
              )}
              {plagiarismStatus === "1" && plagiarismComment && (
                <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                  Verified !!
                </div>
              )}

              {((status === 1 &&
                processToNext === "0" &&
                revision_status !== "2" &&
                plagiarismStatus !== "1") ||
                (status === 2 &&
                  processToNext === "0" &&
                  revision_status !== "2" &&
                  plagiarismStatus !== "1")) &&
              plagiarismStatus !== "3" ? (
                /* status > 2 ?  */
                <>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                      if (btnStatus === "2") {
                        if (!values?.republish_date) {
                          setFieldError(
                            "republish_date",
                            "Republish date is required for revision"
                          );
                          setSubmitting(false);
                          return;
                        } else {
                          handelSave(values, "2");
                        }
                      } else if (btnStatus === "3") {
                        handelSave(values, "3");
                      } else {
                        handelSave(values, "1");
                      }

                      setSubmitting(false);
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        handleChange,
                        setFieldValue,
                        handleSubmit,
                      } = props;

                      const loaderID =
                        values?.is_republish_by_admin === "1" ? "3" : "1";

                      return (
                        <form>
                          <div className="text-14-300-17 color-1717 cmb-14">
                            Submitted file has any plagiarized :
                          </div>
                          <div className="d-flex align-items-center gap-3 cmb-15">
                            <RadioInput
                              label="Yes"
                              labelClassName="ps-2 text-16-300-200 color-black-olive"
                              name="is_republish_by_admin"
                              id="is_republish_by_admin"
                              value="1"
                              checked={values?.is_republish_by_admin === "1"}
                              onChange={handleChange}
                            />
                            <RadioInput
                              label="No"
                              labelClassName="ps-2 text-16-300-200 color-black-olive"
                              name="is_republish_by_admin"
                              id="is_republish_by_admin"
                              value="0"
                              checked={values?.is_republish_by_admin === "0"}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="cmb-16">
                            <TextArea
                              labelClass="text-15-500 mb-1"
                              placeholder="Give your comments..."
                              id="plagiarism_comment"
                              onChange={handleChange}
                              value={values?.plagiarism_comment}
                              rows={3}
                            />
                          </div>
                          <div className="cmb-16">
                            <FileUpload
                              label="Plagiarism Report *"
                              labelClass="text-15-500 mb-1"
                              fileText={getFilenameFromUrl(
                                values?.plagiarism_document_file_name
                              )}
                              id="plagiarism_document"
                              value={values?.plagiarism_document}
                              onChange={(e) => {
                                setFieldValue(
                                  "plagiarism_document",
                                  e.target.value
                                );
                                setFieldValue(
                                  "plagiarism_document_file_name",
                                  e.target.fileName
                                );
                              }}
                              error={errors?.plagiarism_document}
                            />
                          </div>

                          <div className="cmb-16">
                            <DatePicker
                              id="republish_date"
                              label={`Republish Date ${
                                values?.is_republish_by_admin === "1" ? "*" : ""
                              }`}
                              labelClass="text-15-500 mb-1"
                              placeholder="DD-MM-YYYY"
                              minDate={moment()}
                              onChange={(e) => {
                                setFieldValue("republish_date", e.target.value);
                              }}
                              value={values?.republish_date}
                              error={errors?.republish_date}
                            />
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            <Button
                              text={
                                values?.is_republish_by_admin === "1"
                                  ? "Reject"
                                  : "Accept"
                              }
                              btnStyle="primary-dark"
                              className="h-35 cps-15 cpe-15"
                              btnLoading={btnLoader === loaderID}
                              disabled={!values?.plagiarism_document}
                              onClick={() => {
                                setBtnStatus(
                                  values?.is_republish_by_admin === "1"
                                    ? "3"
                                    : "1"
                                );
                                handleSubmit();
                              }}
                            />

                            <Button
                              text="Revision"
                              btnStyle="primary-outline"
                              className="h-35 cps-10 cpe-10"
                              btnLoading={btnLoader === "2"}
                              disabled={!values?.plagiarism_document}
                              onClick={() => {
                                setBtnStatus("2");
                                handleSubmit();
                              }}
                            />
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                  {/* <div className="text-14-300-17 color-1717 cmb-14">
                    Submitted file has any plagiarized :
                  </div>
                  <div className="d-flex align-items-center gap-3 cmb-15">
                    <RadioInput
                      label="Yes"
                      labelClassName="ps-2 text-16-300-200 color-black-olive"
                      name="Yes"
                      id="is_republish_by_admin"
                      value="1"
                      checked={formData?.is_republish_by_admin === "1"}
                      onChange={handleChange}
                    />
                    <RadioInput
                      label="No"
                      labelClassName="ps-2 text-16-300-200 color-black-olive"
                      name="No"
                      id="is_republish_by_admin"
                      value="0"
                      checked={formData?.is_republish_by_admin === "0"}
                      onChange={handelChange}
                    />
                  </div>
                  <div className="cmb-16">
                    <TextArea
                      labelClass="text-15-500 mb-1"
                      placeholder="Give your comments..."
                      id="plagiarism_comment"
                      onChange={handelChange}
                      value={formData?.plagiarism_comment}
                      // value={plagiarismComment}
                      // disabled={status >= 2}
                      rows={3}
                    />
                  </div>
                  <div className="cmb-16">
                    <FileUpload
                      label="Plagiarism Report *"
                      labelClass="text-15-500 mb-1"
                      fileText={getFilenameFromUrl(
                        formData?.plagiarism_document_file_name
                      )}
                      id="plagiarism_document"
                      onChange={handelChange}
                      value={formData?.plagiarism_document}
                      // value={plagiarismDocument}
                      // disabled={status >= 2}
                    />
                  </div>
                  {formData?.is_republish_by_admin === "1" && (
                    <div className="cmb-16">
                      <DatePicker
                        id="republish_date"
                        label="Republish Date *"
                        labelClass="text-15-500 mb-1"
                        placeholder="DD-MM-YYYY"
                        minDate={moment()}
                        onChange={handleDateChange}
                        value={formData?.republish_date}
                        // value={republishDate}
                      />
                    </div>
                  )}

                  <div className="d-flex align-items-center gap-2">
                    <Button
                      text={
                        formData?.is_republish_by_admin === "1"
                          ? "Reject"
                          : "Accept"
                      }
                      btnStyle="primary-dark"
                      className="h-35 cps-15 cpe-15"
                      btnLoading={btnLoader === loaderID}
                      disabled={
                        formData?.plagiarism_document
                          ? formData?.is_republish_by_admin === "1"
                            ? formData?.plagiarism_document &&
                              formData?.republish_date
                              ? false
                              : true
                            : false
                          : true
                      }
                      onClick={() => {
                        handelSave(
                          formData?.is_republish_by_admin === "1" ? "3" : "1"
                        );
                      }}
                    />
                    <Button
                      text="Revision"
                      btnStyle="primary-outline"
                      className="h-35 cps-10 cpe-10"
                      btnLoading={btnLoader === "2"}
                      disabled={
                        formData?.plagiarism_document
                          ? formData?.is_republish_by_admin === "1"
                            ? plagiarismDocument && formData?.republish_date
                              ? false
                              : true
                            : false
                          : true
                      }
                      onClick={() => {
                        handelSave("2");
                      }}
                    />
                  </div> */}
                </>
              ) : (
                !plagiarismStatus && <div className="w-100 cpt-20 cpb-20"></div>
              )}
            </div>
          </div>

          {/* Review */}
          <div className="d-flex justify-content-start align-items-center">
            <div
              className={`steps-block ${
                plagiarismAccepted && status >= 2 ? "active" : "not-active"
              }`}
            >
              {"3"}
            </div>
            <div>
              <div
                className={`text-16-500-20 ${
                  plagiarismAccepted && status >= 2
                    ? "color-new-car"
                    : "color-6866"
                }`}
              >
                Review
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div className="sparator-block">
              <span className="hr-line" />
            </div>
            {plagiarismAccepted && status >= 2 ? (
              <div className="w-50">
                <div className="w-100 d-flex align-items-center gap-3">
                  <div className="w-100 position-relative">
                    <ReviewerDropdown
                      id="allocated_reviewers"
                      label={"Allocate Reviewer"}
                      isRequired
                      options={[]}
                      placeholder="Search Reviewer"
                      optionValue="name"
                      optionKey="name"
                      onChange={(e) => {
                        // console.log("e", e);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-100 cpt-20 cpb-20"></div>
            )}
          </div>
          {/* Acceptance */}
          <div className="d-flex justify-content-start align-items-center">
            <div
              className={`steps-block ${status >= 4 ? "active" : "not-active"}`}
            >
              {"4"}
            </div>
            <div>
              <div
                className={`text-16-500-20 ${
                  status >= 4 ? "color-new-car" : "color-6866"
                }`}
              >
                Acceptance
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div className="sparator-block">
              <span className="hr-line" />
            </div>
            <div className="w-100 cpt-20 cpb-20"></div>
          </div>
          {/* Registration */}
          <div className="d-flex justify-content-start align-items-center">
            <div
              className={`steps-block ${status >= 4 ? "active" : "not-active"}`}
            >
              {"5"}
            </div>
            <div>
              <div
                className={`text-16-500-20 ${
                  status >= 4 ? "color-new-car" : "color-6866"
                }`}
              >
                Registration
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div className="sparator-block"></div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default NewPaperDetails;

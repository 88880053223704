import React from "react";

const ReviewReportDetails = () => {
  return (
    <div className="form-block cps-24 cpt-12 cpe-24 cpb-12">
      <div className="row">
        <div className="col-md-6">
          <div className="text-14-500-17 color-6866 cmb-12">Abstract ID :</div>
          <div className="text-14-300-17 color-6866 cmb-24">
            WCASET, Kuala Lumpur_KUA_0037
          </div>

          <div className="text-14-500-17 color-6866 cmb-12">
            Manuscript Title :
          </div>
          <div className="text-14-300-17 color-6866 cmb-24">
            Market Orientation and Performance of Companies in Indonesia: A
            Bibliometric Analysis
          </div>

          <div className="text-14-500-17 color-6866 cmb-12">
            Recommendation :
          </div>
          <div className="text-14-300-17 color-6866 cmb-24">
            Recommended for minor revision as per the comments for improvement.
            Abstract is comprehensive containing the context, purpose, methods,
            findings and significance.
          </div>

          <div className="text-14-500-17 color-6866 cmb-12">
            General Comments :
          </div>
          <div className="text-14-300-17 color-6866 cmb-24">
            The study is relevant and timely in helping the companies in
            Indonesia. The statements are somehow broad and needed further
            improvement on the information specified below. I commend the
            researcher in using the Scopus data set in using the bibliometric
            analysis.
          </div>
        </div>

        <div className="col-md-6">
          <div className="text-14-500-17 color-6866 cmb-12">
            Followed Rates :
          </div>
          <div className="text-14-300-17 color-6866 cmb-24">
            (1 = Excellent) , (2 = Good) , (3 = Fair) , (4 = Poor)
          </div>
          <div className="border br-5 cpt-25 cpb-16">
            <div className="d-flex align-items-center justify-content-center text-16-500-200 color-new-car border-bottom cpb-16">
              Evaluation Report
            </div>
            <div className="cpt-23 cpb-16 border-bottom">
              {[
                { label: "Originality", score: "2" },
                { label: "Contribution to the Field", score: "2" },
                { label: "Technical Quality", score: "2" },
                { label: "Clarity of Presentation", score: "2" },
                { label: "Depth of Research", score: "2" },
              ].map((item, index) => (
                <div
                  className="d-flex align-items-center cmb-16 text-14-500-17 color-6866 row"
                  key={index}
                >
                  <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                    {item.label}{" "}
                    <span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
                  </div>
                  <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                    {item.score}
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex align-items-center text-14-500-17 color-6866 cpt-16 row">
              <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                Overall<span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
              </div>
              <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                2.2 (Good)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 text-14-500-17 color-6866 cmb-12">
        <div>Abstract Result :</div>
        <div>Abstract ResultAccept as it is</div>
      </div>
    </div>
  );
};

export default ReviewReportDetails;
